<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>출석부</sub-title>
        <div class="cul_main">
          <div class="box" style="margin: 0 auto">
            <div class="date">
              {{ month }} 월 출석정보
              <p style="font-size: 10px;color: #cccccc">일 입금총 액수가
                {{ $store.state.userInfo.rank.culcheckrechargeamount|comma }}원 이상일경우 출석으로 인정</p>
            </div>
            <div class="week">일</div>
            <div class="week">월</div>
            <div class="week">화</div>
            <div class="week">수</div>
            <div class="week">목</div>
            <div class="week">금</div>
            <div class="week">토</div>
            <div class="day" v-for="(item,index) in culchekinfo.daysinfo" :class="{'day2':item.day===0&&index>=35}">
              <div class="daynumber" v-if="item.day>0">{{ item.day }}</div>
              <!--                            <i class="fa fa-check-circle-o ck" v-if="item.isculckeck"></i>-->
              <img src="../../assets/images/logo/dst/logo01.png" v-if="item.isculckeck" style="width: 80%" alt="">
            </div>
            <div style="line-height: 22px;text-align: center;color: #a1a1a1;width: 100%" v-if="$store.state.userInfo.rank.loginEventUse == sportsConst.YES">
              모든 포인트는 자동 지급됩니다
            </div>
            <div v-if="$store.state.userInfo.rank.loginEventUse == sportsConst.YES"
                 style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;width: 100%">
              <span class="record1"
                    v-if="$store.state.userInfo.rank.loginEvent3 && $store.state.userInfo.rank.loginEvent3>0">5일  {{ $store.state.userInfo.rank.loginEvent3|comma }} 포인트</span>
              <span class="record2"
                    v-if="$store.state.userInfo.rank.loginEvent7 && $store.state.userInfo.rank.loginEvent7>0">10일  {{ $store.state.userInfo.rank.loginEvent7|comma }} 포인트</span>
              <span class="record1"
                    v-if="$store.state.userInfo.rank.loginEvent14 && $store.state.userInfo.rank.loginEvent14>0">15일 {{ $store.state.userInfo.rank.loginEvent14|comma }} 포인트</span>
              <span class="record2"
                    v-if="$store.state.userInfo.rank.loginEvent21 && $store.state.userInfo.rank.loginEvent21>0">21일 {{ $store.state.userInfo.rank.loginEvent21|comma }} 포인트</span>
              <span class="record1"
                    v-if="$store.state.userInfo.rank.loginEvent28 && $store.state.userInfo.rank.loginEvent28>0">30일 {{ $store.state.userInfo.rank.loginEvent28|comma }} 포인트</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>
  </div>

</template>

<script>
import TopbarComp from "../../components/TopbarComp";
import LeftBarComp from "../../components/LeftBarComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import RightBarComp from "../../components/RightBarComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import FootComp from "../../components/FootComp";
import sportsConst from "../../common/sportsConst";
import SubTitle from "../../components/SubTitle";
import {getCulcheck} from "../../network/userRequest";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import {postionMixin} from "../../common/mixin";

export default {
  mixins: [postionMixin],
  components: {
    SportsBetCartComp,
    RightButtonsComp,
    UserInfoComp,
    SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
  },
  data() {
    return {
      sportsConst,
      culchekinfo: {},
      month: 0,
      position: "출석부",

    }
  },
  mounted() {

  },
  methods: {},
  created() {
    this.month = this.$moment().month() + 1
    getCulcheck().then(res => {
      if (res.data.success) {
        let data = res.data.data;
        let daysinfo = []
        let lastDay = this.$moment().daysInMonth();
        let startWeek = this.$moment().startOf('month').weekday()
        let daynumber = 1;
        for (let i = 0; i < 42; i++) {
          let d = {}
          this.$set(d, 'isculckeck', false)
          if (i < startWeek) {
            this.$set(d, 'day', 0)
          } else if (i >= (lastDay + startWeek)) {
            this.$set(d, 'day', 0)
          } else {
            this.$set(d, 'day', daynumber)
            daynumber++

            data.map(resdata => {
              if (resdata.sday == d.day) this.$set(d, 'isculckeck', true)
            })
          }


          daysinfo.push(d)
        }
        this.$set(this.culchekinfo, 'startWeek', this.$moment().startOf('month').weekday())
        this.$set(this.culchekinfo, 'daysinfo', daysinfo)
        console.log(this.culchekinfo)
      }
    })
  }
}
</script>

<style scoped>
.cul_main {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.box {
  width: 100%;
  max-width: 750px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.box .date {
  width: 100%;
  height: 68px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: var(--activeLeisuerGame1);
}

.box .week {
  width: 14%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: var(--tableThBg);
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.box .day {
  width: 14%;
  height: 99px;
  text-align: center;
  font-size: 12px;
  color: #00ca18;
  position: relative;
  border: 1px solid var(--activeLeisuerGame1);
  margin-top: 5px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .day2 {
  border: 0 !important;
}

.day .daynumber {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 3px;
  top: 3px;
  color: #fff2e3;
  font-weight: bold;
  font-size: 14px;

}

.day .ck {
  font-size: 48px;
  color: var(--activeLeisuerGame1);
}

.record1{
  color: deepskyblue;
  width: 19.3%;
  line-height: 28px;
  border: 1px solid #787878;
  margin: 3px;
  text-align: center;
  border-radius: 5px;
}
.record2{
  color: #ffa500;
  width: 19.3%;
  line-height: 28px;
  border: 1px solid #787878;
  margin: 3px;
  text-align: center;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .box .day {
    height: 52px !important;
  }

  .day .ck {
    font-size: 23px !important;
  }

  .day .daynumber {
    width: 15px;
    height: 15px;
    font-size: 11px;

  }

  .record1{
    width: 49.5% !important;
  }
  .record2{
    width: 49.5% !important;
  }


}


</style>